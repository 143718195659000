import { graphql } from 'gatsby';
import React, { FC } from 'react';
import { Layout } from 'src/components/Layout';
import { PitchQuery } from 'src/graphqlTypes';

interface PitchProps {
    data: PitchQuery;
}

const Pitch: FC<PitchProps> = ({ data }) => {
    console.log(data.contentfulPitch);
    return (
        <Layout>
            <div>{data.contentfulPitch?.pitchTitle}</div>
            <div>{data.contentfulPitch?.shortDescription}</div>
            <div>Got deal? {data.contentfulPitch?.dealInitiated ? 'Yes' : 'No'}</div>
        </Layout>
    );
};

export const query = graphql`
    query Pitch($slug: String!) {
        contentfulPitch(slug: { eq: $slug }) {
            id
            pitchTitle
            slug
            website
            shortDescription
            dealSought
            dealInitiated
            amazonLink
            seoMetaDescription
            seoTitle
            slug
            termsOfDeal
            episode {
                id
                episodeNumber
            }
        }
    }
`;

export default Pitch;
